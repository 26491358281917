<!--
 * @Description: 房东计算账单详情-核对
 * @Author: 琢磨先生
 * @Date: 2022-08-09 02:22:56
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-03-12 13:02:11
-->
<template>
  <el-drawer
    title="账单详情"
    v-model="visibleDrawer"
    size="1200px"
    @closed="onClosed"
    custom-class="bill_custom_class"
    :destroy-on-close="true"
  >
    <dtl-page :model="model"  @reload="loadData"></dtl-page>

    <template #footer>
      <el-button
        type="danger"
        @click="onRebuild"
        :loading="rebuilding"
        :disabled="saving"
        v-power="'seller/v1/landlordbill/rebuild'"
        >重新生成</el-button
      >
      <el-button
        type="primary"
        :loading="saving"
        :disabled="rebuilding"
        v-power="'seller/v1/landlordbill/openConfirm'"
        @click="onSubmitReview"
        >开放确认</el-button
      >
    </template>
  </el-drawer> 
  <exp-dtl :id="bill_exp_id" @close="bill_exp_id = ''"></exp-dtl>
</template>

<script>
// import EditExpense from "../../../house/expenditure/edit_expense.vue";
import ExpDtl from "../exp_dtl.vue";
import DtlPage from '../dtl_page.vue'

export default {
  components: {
    DtlPage,
    ExpDtl,
  },
  data() {
    return {
      loading: false,
      visibleDrawer: false,
      //提交复核中
      saving: false,
      //重建当中
      rebuilding: false,
      //显示导入弹框
      visibleDialog: false,
      //编辑费用id
      edit_expense_id: "",
      model: {},
      //查看费用支出详情的id
      bill_exp_id: "",
    };
  },
  emits: ["close", "success"],
  props: ["id"],
  watch: {
    id: {
      handler() {
        if (this.id) {
          this.visibleDrawer = true;
          this.loadData();
        }
      },
      immediate: true,
    },
  },
  created() {},
  methods: {
    /**
     * 加载详情
     */
    loadData() {
      this.loading = true;
      this.$http
        .get("seller/v1/LandlordBill/dtl?id=" + this.id)
        .then((res) => {
          if (res.code == 0) {
            this.model = res.data;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onClosed() {
      this.$emit("close");
    },

    /**
     * 修改费用支出
     * @param {*} item
     */
    onEditExpense(item) {
      this.edit_expense_id = item.expense_record_id;
      this.visibleDialog = true;
    },

    /**
     * 删除费用支出
     */
    onDeleteExpense(item) {
      this.$http
        .get("seller/v1/expense/record/del?id=" + item.expense_record_id)
        .then((res) => {
          if (res.code == 0) {
            this.$message({ type: "success", message: res.msg });
            this.loadData();
          }
        });
    },

    /**
     *
     */
    expenseTableRowClass(o) {
      console.log(o.row);
      if (o.row.original_is_deleted) {
        return "tr_delete_row";
      }
      if (o.row.original_is_new) {
        return "tr_new_row";
      }
      if (o.row.is_wrong) {
        return "tr_wrong_row";
      }
    },

    /**
     * 创建结算账单
     */
    onRebuild() {
      this.$confirm(
        "重新生成账单后回退草稿状态、确定要重新生成？",
        "提醒"
      ).then(() => {
        this.rebuilding = true;
        this.$http
          .get("seller/v1/LandlordBill/rebuild?id=" + this.id)
          .then((res) => {
            if (res.code == 0) {
              this.$message.success(res.msg);
              this.loadData();
            }
          })
          .finally(() => {
            this.rebuilding = false;
          });
      });
    },

    /**
     * 查看费用支出详情
     */
    onViewExpDtl(item) {
      console.log(item);
      this.bill_exp_id = item.id;
    },

    /**
     * 创建结算账单
     */
    onSubmitReview() {
      this.$confirm("确定开放确认？", "提醒").then(() => {
        this.rebuilding = true;
        this.$http
          .get("seller/v1/LandlordBill/openConfirm?id=" + this.id)
          .then((res) => {
            if (res.code == 0) {
              this.$message.success(res.msg);
              this.$emit("success");
              this.visibleDrawer = false;
            }
          })
          .finally(() => {
            this.rebuilding = false;
          });
      });
    },
  },
};
</script>
 